import { getMessage } from '~/assets/utils';

//pages import
import Home from '~/views/home/content.jsx';
import Login from '~/views/login/index.jsx';
import Favorites from '~/views/favorites/index.jsx';
import ShoppingCart from '~/views/shopping/index.jsx';
import Search from '~/views/search/index.jsx';
import Product from '~/views/product/index.jsx';
import Category from '~/views/category/index.jsx';
import Terms from '~/views/terms/index.jsx';
import ForgotPassword from '~/views/login/forgot_password.jsx';
import Transfer from '~/views/transfer/index.jsx';
import RecoveryPassword from '~/views/login/recovery_password.jsx';
import ShoppingHistory from '~/views/shopping/history.jsx';
import Page404 from '~/views/404/index.jsx';
import AccountPage from '~/views/account/index.jsx';
import Payment from '~/views/payment/index.jsx';
import Group from '~/views/group/index.jsx';

var indexRoutes = [
    {
        path: "/payment",
        name: getMessage('payment'),
        component: Payment,
        only: 'logged'
    },
    {
        path: "/transfer",
        name: getMessage('transfer'),
        component: Transfer,
        only: 'logged'
    },
    {
        path: "/404",
        name: getMessage('not_found'),
        component: Page404,
        only: 'all'
    },
    {
        path: "/account",
        name: getMessage('my_account'),
        component: AccountPage,
        only: 'logged'
    },
    {
        path: "/terms",
        name: getMessage('terms'),
        component: Terms,
        only: 'all'
    },
    {
        path: "/forgot-password",
        name: getMessage('forgot_password'),
        component: ForgotPassword,
        only: 'all'
    },
    {
        path: "/recovery-password",
        name: getMessage('recovery_password'),
        component: RecoveryPassword,
        only: 'all'
    },
    {
        path: "/shopping-history",
        name: getMessage('shopping_history'),
        component: ShoppingHistory,
        only: 'logged'
    },
    {
        path: "/category/*",
        name: getMessage('category'),
        component: Category,
        only: 'all'
    },
    {
        path: "/login",
        name: getMessage('login'),
        component: Login,
        only: 'not-logged'
    },
    {
        path: "/favorites",
        name: getMessage('favorites'),
        component: Favorites,
        only: 'logged'
    },
    {
        path: "/shopping-cart",
        name: getMessage('shopping_cart'),
        component: ShoppingCart,
        only: 'logged'
    },
    {
        path: "/search",
        name: getMessage('search'),
        component: Search,
        only: 'all'
    },
    {
        path: "/product",
        name: getMessage('product'),
        component: Product,
        only: 'all'
    },
    {
        path: "/group/*",
        name: getMessage('group'),
        component: Group,
        only: 'all'
    },
    {
        path: "/",
        name: process.env.REACT_APP_NAME,
        component: Home,
        only: 'all'
    }
];

export default indexRoutes;