import user from '~/reducers/user';

let defaultFirebase = require('~/firebase/index.js');
const firebase = defaultFirebase.default;
const { getFirebaseData } = require('~/firebase/functions');
/**
 * Salva as alterações de infomações do usuário
 * @param {*} user_data - dados dos usuário, recebidos pela função registerUser
 */
export const userSaveInfo = async (user_data) => {
    let user_id = user_data.user_id;
    if (!user_id || !user_data) {
        return {
            status: false,
            message: 'fields_needed'
        }
    }
    try {
        let phone = '';
        if (user_data.phone.match(/\d+/)) {
            phone = user_data.phone.match(/\d+/g).map(Number).join('');
        }
        if (phone.toString().length === 0) {
            return {
                status: false,
                message: 'fields_needed'
            }
        }
        await firebase.database().ref('users/' + user_id).update({
            name: user_data.name,
            surname: user_data.surname,
            phone: phone
        });
        return {
            status: true
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna os produtos favoritos de um usuário
 * @param {*} user_id - ID do usuário
 */
export const userFavorites = async (user_id) => {
    if (!user_id) {
        return {
            status: false,
            message: 'invalid_user'
        }
    }
    try {
        let response = await getFirebaseData('users/' + user_id + '/favorites');
        if ( response.status === true ) {
            let favorites = response.data;
            if ( !Array.isArray(favorites) ) {
                favorites = [];
            }
            return {
                status: true,
                favorites: favorites
            }
        } else {
            return {
                status: false,
                message: 'none_favorites'
            }
        }
    }
    catch(error) {
        let error_code = error.code || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}