import Axios from 'axios';

const axios = require('axios');
let defaultFirebase = require('~/firebase/index.js');
const firebase = defaultFirebase.default;
const { userFavorites } = require('~/assets/requests/user.js');
const { getProductData, getAttributes } = require('./firebase');
const { getFirebaseData, systemSettings } = require('~/firebase/functions');
/**
 * Adiciona um produto à lista de favoritos
 * @param {*} product_id - ID do produto
 */
export const addProductToFavorites = async (product_id) => {
    let user_id = localStorage.getItem('user_id');
    if (!product_id || !user_id) {
        return {
            status: false,
            message: 'invalid_product_id'
        }
    }
    try {
        let response_favorites = await userFavorites(user_id);
        if (response_favorites.status === false) {
            return {
                status: false
            }
        }
        let products = response_favorites.data;
        if ( !Array.isArray(products) ) {
            products = [];
        }
        products.push(product_id);
        await firebase.database().ref('users/' + user_id + '/favorites').set(products);
        return {
            status: true
        }
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Remove um produto da lista de favoritos
 * @param {*} product_id - ID do produto
 */
export const removeFromFavorites = async (product_id) => {
    let user_id = localStorage.getItem('user_id');
    if (!product_id || !user_id) {
        return {
            status: false,
            message: 'invalid_product_id'
        }
    }
    try {
        let response_favourites = await userFavorites(user_id);
        if (response_favourites.status === false) {
            return {
                status: false
            }
        }
        let products = response_favourites.favorites;
        if ( !Array.isArray(products) || products.length === 0 ) {
            return {
                status: false
            }
        }
        let indexOf = products.indexOf(product_id);
        if (indexOf >= 0) {
            products.splice(indexOf, 1);
        }
        await firebase.database().ref('users/' + user_id + '/favorites').set(products);
        return {
            status: true
        }
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retornas os produtos adicionados aos favoritos
 */
export const getFavoriteProducts = async () => {
    let user_id = localStorage.getItem('user_id');
    if (!user_id) {
        return {
            status: false,
            message: 'invalid_user'
        }
    }
    try {
        let responseFavorites = await userFavorites(user_id);
        if (responseFavorites.status === false) {
            return {
                status: false
            }
        }

        let favorites = responseFavorites.favorites;
        let products = [];
        let loadPromisses = favorites.map( async product_id => {
            let responseProduct = await getProductData(product_id);
            if ( responseProduct.status === true ) {
                products.push(responseProduct.product);
            }
            return true;
        });
        await Promise.all(loadPromisses);
        return {
            status: true,
            products: products
        }
        
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Adiciona um produto ao carrinho
 * @param {*} product - Produto incluindo o atributo selecionado
 */
export const addProductToCart = async (product) => {
    try {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        if ( !Array.isArray(cart) ) cart = [];
        if ( !product.product_id || !product.attribute_id || !product.attribute_value ) {
            return {
                status: false
            }
        }
        const { product_id, attribute_id, attribute_value } = product;
        if ( cart.length > 0 ) {
            let indexOf = cart.findIndex(item => item.product_id === product_id && item.attribute_id === attribute_id && item.attribute_value === attribute_value);
            if (indexOf >= 0) {
                cart[indexOf].quantity = cart[indexOf].quantity+1;
            } else {
                product.quantity = 1;
                cart.push(product);
            }
            localStorage.setItem('cart', JSON.stringify(cart));
            return {
                status: true
            };
        } else {
            product.quantity = 1;
            cart.push(product);
            localStorage.setItem('cart', JSON.stringify(cart));
            return {
                status: true
            };
        }
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Remove um produto do carrinho
 * @param {*} product - Produto incluindo o atributo selecionado
 */
export const removeProductFromCart = async (product) => {
    try {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        if ( !Array.isArray(cart) ) cart = [];

        const { product_id, attribute_id, attribute_value } = product;
        if ( cart.length > 0 ) {
            let indexOf = cart.findIndex(item => item.product_id === product_id && item.attribute_id === attribute_id && item.attribute_value === attribute_value);
            if (indexOf >= 0) {
                if ( cart[indexOf].quantity > 1 ) {
                    cart[indexOf].quantity -= 1;
                } else if ( cart[indexOf].quantity === 1 ) {
                    cart.splice(indexOf, 1);
                }
                localStorage.setItem('cart', JSON.stringify(cart));
                return {
                    status: true
                };
            }
        } else {
            return {
                status: false
            }
        }
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Remove todo o produto do carrinho
 * @param {*} product - Produto incluindo o atributo selecionado
 */
export const removeAllProductFromCart = async (product) => {
    try {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        if ( !Array.isArray(cart) ) cart = [];

        const { product_id, attribute_id, attribute_value } = product;
        if ( cart.length > 0 ) {
            let indexOf = cart.findIndex(item => item.product_id === product_id && item.attribute_id === attribute_id && item.attribute_value === attribute_value);
            if (indexOf >= 0) {
                cart.splice(indexOf, 1);
                localStorage.setItem('cart', JSON.stringify(cart));
                return {
                    status: true
                };
            } else {
                return {
                    status: false
                }
            }
        } else {
            return {
                status: false
            }
        }
    }
    catch(error) {
        return {
            status: false,
            message: error
        }
    }
}
/**
 * Retorna todos os produtos adicionados ao carrinho
 */
export const getProductsCart = async () => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    if ( !Array.isArray(cart) ) cart = [];

    try {
        let products = [];
        let attributes = [];
        let total_price = 0;
        let loadPromisses = cart.map( async (product) => {
            if ( !product.product_id || !product.attribute_id || !product.attribute_value ) {
                return false;
            }
            let responseProduct = await getProductData(product.product_id);
            if ( responseProduct.status === true ) {
                total_price += (responseProduct.product.price * product.quantity);
                responseProduct.product.attribute_selected = {
                    _id: product.attribute_id,
                    value: product.attribute_value
                };
                responseProduct.product.quantity = product.quantity;
                products.push(responseProduct.product);
            }
            return true;
        });
        await Promise.all(loadPromisses);
        let response = await getAttributes();
        if ( response.status === true ) {
            attributes = response.attributes;
        }
        return {
            status: true,
            products: products,
            attributes: attributes,
            total_price: total_price
        }
        
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna as cidades disponíveis para venda do produto
 */
export const availableLocations = async () => {
    try {
        let repsonse = await getFirebaseData('availableLocations');
        if ( repsonse.status === true ) {
            return {
                status: true,
                location: repsonse.data
            }
        } else {
            return {
                status: false,
                message: 'nothing_location_available'
            }
        }
        
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna os dados de entrega do produto caso válido o endereço
 * @param {*} zip_code 
 */
export const getAddressDelivery = async (zip_code) => {
    if (!zip_code) {
        return {
            status: false
        }
    }
    try {
        const body = {
            "origin": zip_code,
            "destiny": zip_code,
            "weight": 20,
            "length": 20,
            "height": 20,
            "width": 20,
            "diameter": 20
        }
        const options = {}

        let response = await axios.post('https://us-central1-carimbos-avenida.cloudfunctions.net/fareCalc', body, options);
        if ( response.data && response.data.success === true ) {
            return {
                status: true,
                data: response.data
            }
        } else {
            return {
                status: false
            }
        }
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Cria um novo pedido de produtos
 * @param {*} order_data - dados do pedido
 * @return retorna o id do pedido
 */
export const createOrder = async (order_data) => {
    try {
        let order_id = localStorage.getItem('order_id');
        if (order_id) {
            return {
                status: true,
                order_id: order_id
            }
        }
        let user_id = localStorage.getItem('user_id');
        if (!user_id) {
            return {
                status: false
            }
        }

        order_data.user_id = user_id;

        let products = [];
        let localProducts = JSON.parse(localStorage.getItem('cart')) || [];
        let localProductsByCarimbo = JSON.parse(localStorage.getItem('cartCarimbo')) || [];
        if ( (!Array.isArray(localProducts) || localProducts.length === 0)
            && (!Array.isArray(localProductsByCarimbo) || localProductsByCarimbo.length === 0)) {
            return {
                status: false
            }
        }
        let loadPromisses = localProducts.map(async product => {
            let responseProducts = await getFirebaseData('products/' + product.product_id);
            if ( responseProducts.status === true ) {
                responseProducts.data.key = product.product_id;
                let attributes = responseProducts.data.attributes;
                let attribute_key = undefined;

                for (let key in attributes) {
                    for ( let subKey in attributes[key] ) {
                        if ( subKey === product.attribute_id && attributes[key][subKey] === product.attribute_value ) {
                            attribute_key = key;
                        }
                    }
                }

                if (!attribute_key) {
                    return {
                        status: false,
                        message: 'empty_products'
                    }
                }
                let newAttribute = {};
                newAttribute[attribute_key] = {
                    ...attributes[attribute_key]
                }

                products.push({
                    attributes: newAttribute,
                    product: responseProducts.data,
                    quantity: product.quantity
                });
            }
            return true;
        });

        await Promise.all(loadPromisses);
        if ((products.length === 0) && (localProductsByCarimbo.length === 0)) {
            return {
                status: false,
                message: 'cannot_create_order'
            }
        }
        const totalPrice    = order_data.fare_price + order_data.total_price;
        let settings = await systemSettings();
        const deliveryMode = parseInt(order_data.deliveryMode)

        let newData = {
          createdAt: new Date().getTime(),
          delivery:
            deliveryMode === 1
              ? 0
              : settings.data.paymentSettings.staticDelivery,
          deliveryAddress: {
            additional: deliveryMode === 1 ? "" : order_data.complement,
            address: deliveryMode === 1 ? "" : order_data.street,
            city: deliveryMode === 1 ? "" : order_data.city,
            district: deliveryMode === 1 ? "" : order_data.district,
            number: deliveryMode === 1 ? "" : order_data.number,
            state: order_data.state ? order_data.state : "SC",
            zip_code: deliveryMode === 1 ? "" : order_data.zip_code,
          },
          deliveryMode: order_data.deliveryMode,
          discount: 0,
          fare: order_data.fare_price,
          price: order_data.total_price,
          products: products,
          productsByCarimbo: localProductsByCarimbo,
          status: 1,
          totalPrice: totalPrice,
          user: user_id,
          fileURL: order_data.fileURL || "",
        };

        let response = await firebase.database().ref('orders').push(newData).key;
        if ( response ) {
            return {
                status: true,
                order_id: response
            }
        } else {
            return {
                status: false
            }
        }
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna as informações de um pedido
 * @param {*} orderId - id do pedido
 */
export const getOrderData = async (orderId) => {
    try {
        if (!orderId) {
            return {
                status: false,
                message: 'invalid_order'
            }
        }
        let response = await getFirebaseData('orders/' + orderId);
        if ( response.status === true ) {
            return {
                status: true,
                data: response.data
            }
        } else {
            return {
                status: false,
                message: 'invalid_order'
            }
        }
    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Realiza um pagamento
 * @param {*} orderId 
 */
export const processPaymentOrder = async (payment_data) => {
    try {
        if (!payment_data) {
            return {
                status: false,
                message: 'invalid_order'
            }
        }

        localStorage.setItem('order_id', payment_data.id);
        let order = await getOrderData(payment_data.id);
        
        const options = {headers: {'Access-Control-Allow-Origin': '*'}}
        const body = payment_data;

        if (order.status === true) {
            let response = await axios.post('https://us-central1-carimbos-avenida.cloudfunctions.net/paymentByCard', body, options);
            if ( response.data && response.data.success === true ) {
                localStorage.removeItem('order_id');
                localStorage.removeItem('cart');
                //localStorage.removeItem('cartCarimbo');
                return {
                    status: true
                }
            } else {
                return {
                    status: false,
                    message: response.data.errorCode === -1 && 'payment_error'
                }
            }
        } else {
            return {
                status: false,
                message: 'invalid_order'
            }
        }

    }
    catch(error) {
        let error_code = error.message || 'request_failed';
        error_code = error_code.replace(/[/\\-]/g, '_');
        error_code = error_code.toLowerCase();
        if (error_code.length === '') error_code = 'request_failed';
        return {
            status: false,
            message: error_code
        }
    }
}
/**
 * Retorna os pedidos de um usuário
 * @return Object
 */
export const getProductsOrders = async () => {
    try {
        let user_id = localStorage.getItem('user_id');
        if (!user_id) {
            return {
                status: false
            }
        }

        let consultFirebase = await firebase.database().ref('orders').orderByChild("user").equalTo(user_id).once('value');
        let response = consultFirebase.val();
        let orders = [];
        if ( response && Object.keys(response).length > 0 ) {
            let attributes = [];
            let promiseProducts = await Object.values(response).map( async (item, key) => {
                if (item.products) {
                    item._id = Object.keys(response)[key];
                    item.products.map( async (subItem, key) => {
                        subItem.product.attribute_selected = {
                            _id: Object.keys(subItem.attributes)[0],
                            value: Object.values(subItem.attributes)[0]
                        };
                        subItem.product.quantity = subItem.quantity;
                        subItem.product._id = subItem.product.key
                        item.products[key] = subItem.product;
                        return true;
                    });
                    orders.push(item);
                }
                else if (item.productsByCarimbo) {
                    item._id = Object.keys(response)[key];
                    orders.push(item);
                }
                return true;
            });
            await Promise.all(promiseProducts);
            let responseAttributes = await getAttributes();
            if ( responseAttributes.status === true ) {
                attributes = responseAttributes.attributes;
            }
            orders.sort((a, b) => {
                return b.createdAt - a.createdAt;
            })
            return {
                status: true,
                orders: orders,
                attributes: attributes
            }
        } else {
            return {
                status: false,
                message: 'empty_orders'
            }
        }

    }
    catch(error) {
        return {
            status: false,
            error
        }
    }

}
/**
 * Retorna uma lista de produtos a partir de uma pesquisa
 * @return Object
 */
export const getProductsBySearch = async (search) => {
    try {
        let consultFirebase = await firebase.database().ref('products').orderByChild("name").startAt(search).endAt(search+"\uf8ff").once('value');
        let consultFirebase1 = await firebase.database().ref('products').orderByChild("name").startAt(search).once('value');
        let consultFirebase2 = await firebase.database().ref('products').orderByChild("name").endAt(search+"\uf8ff").once('value');
        let response = consultFirebase.val() || consultFirebase1.val() || consultFirebase2.val();
        if ( response && Object.keys(response).length > 0 ) {
            let products = [];
            let attributes = [];
            let promiseProducts = await Object.keys(response).map( async product_id => {
                let responseProduct = await getProductData(product_id);
                if (responseProduct.status === true) {
                    products.push(responseProduct.product);
                }
                return true;
            });
            await Promise.all(promiseProducts);
            return {
                status: true,
                products: products,
                attributes: attributes,
                ads: {}
            }
        } else {
            return {
                status: false,
                message: 'empty_orders'
            }
        }

    }
    catch(error) {
        return {
            status: false,
            error
        }
    }

}
/**
 * Retorna uma lista de cupons
 */
export const getCupoms = async () => {
    try {
        let response =  await getFirebaseData('vouchers');
        if (response.status === true) {
            let discounts = [];
            Object.keys(response.data).forEach(item => {
                let discount = {
                    _id: item
                }
                for (let key in response.data[item]) {
                    discount[key] = response.data[item][key]
                }
                discounts.push(discount);
            });
            return {
                status: true,
                data: discounts
            }
        } else {
            return {
                status: false,
                message: 'none_discount'
            }
        }
    }
    catch(error) {
        return {
            status: false,
            message: error.message
        }
    }
}
/**
 * Carrega os dados de um pedido via Carimbo Web
 * @returns Promise
 */
export const getOrderByCarimbo = async (pedidoId, token) => {
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await Axios.get(`${process.env.REACT_APP_API}/api/pedido/${pedidoId}`, options);
        if (response.data) {
            return {
                status: true,
                data: response.data
            }
        }
    }
    catch (error) {
        return {
            status: false,
            message: error.message
        }
    }
}