/**
 *  firebase/index.js
 *  Ecoville
 *
 *  Last update by Bruno Bandeira on 17/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC74_NzBkVKuOpLydhIoFJSg-Ki9eO7DWE",
  authDomain: "carimbos-avenida.firebaseapp.com",
  databaseURL: "https://carimbos-avenida.firebaseio.com",
  projectId: "carimbos-avenida",
  storageBucket: "carimbos-avenida.appspot.com",
  messagingSenderId: "936906436852",
  appId: "1:936906436852:web:d3688055f7e2ad2a982ea1",
  measurementId: "G-GEYEVCYJCX"
  };

firebase.initializeApp(firebaseConfig);
export default firebase;