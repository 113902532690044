import React from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '~/components/footer/index';

import { getMessage, buttonBeforeLoading, buttonToLoading } from '~/assets/utils';
import { registerUser, loginUser } from '~/assets/requests/auth';
import ReactInputMask from 'react-input-mask';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading',
            submited: false,
            login: {
                email: '',
                password: '',
                message_error: ''
            },
            register: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                repeat_password: '',
                phone: '',
                terms_is_accepted: false,
                message_error: '',
                sharcode: ''
            },
            loaded: false,
            message_register_success: '',
            message_register_failed: '',
            message_login_failed: '',
            section_login: 'login',
            logged: false,
            user: {
                createdAt: 0,
                email: '',
                name: '',
                phone: '',
                status: '',
                surname: '',
                user_id: 0,
                sharcode: ''
            },
            redirectTo: null
        }

        this.handleChangeLogin = (event) => {
            const { login } = this.state;
            login[event.target.name] = event.target.value;
            login.message_error = '';
            this.setState({ login });
        }
        this.handleChangeRegister = (event) => {
            const { register } = this.state;
            register[event.target.name] = event.target.value;
            register.message_error = '';
            this.setState({ register });
        }
        this.redirectTo = () => {
            if (this.state.redirectTo.toString().match(/carimbos-avenida-editor\.web\.app/g) || this.state.redirectTo.toString().match(/35\.202\.149\.7:4200/g)) {
                window.location.href = `${this.state.redirectTo}?tokenSession=${localStorage.getItem('CARIMBOSAVENIDA@TOKEN')}`
            }
            else {
                window.location.href = this.state.redirectTo;
            }
        }
        this.submitLogin = async (e) => {
            e.preventDefault();
            if (this.state.submited === true) return false;
            buttonBeforeLoading('btn-loading');
            buttonToLoading('btn-loading-login');

            const { login } = this.state;
            login.message_error = '';
            this.setState({ submited: true, message_login_failed: '', login });

            for (let field in login) {
                if (field !== 'message_error' && login[field].length === 0) {
                    login.message_error = field + '_is_required';
                    this.setState({ login, submited: false });
                    buttonBeforeLoading('btn-loading');
                    return false;
                }
            }

            let response = await loginUser(login);
            if (response.status === true) {
                if (this.state.redirectTo) {
                    this.redirectTo();
                }
                else {
                    const prevPage = JSON.parse(localStorage.getItem('prevPage')) || null;
                    localStorage.removeItem('prevPage');
                    window.location.href = prevPage || process.env.REACT_APP_URL
                }
            } else {
                this.setState({ message_login_failed: response.message, submited: false });
            }
            buttonBeforeLoading('btn-loading');
        }
        this.submitRegister = async (e) => {
            e.preventDefault();
            if (this.state.submited === true) return false;
            buttonBeforeLoading('btn-loading');
            buttonToLoading('btn-loading-register');

            const { register } = this.state;
            register.message_error = '';
            this.setState({ submited: true, message_register_failed: '', message_register_success: '', register });

            for (let field in register) {
                if (field !== 'message_error' && field !== 'sharcode' && typeof register[field] === 'string' && register[field].length === 0) {
                    register.message_error = field + '_is_required';
                    this.setState({ register, submited: false });
                    buttonBeforeLoading('btn-loading');
                    return false;
                }
            }

            if (register.terms_is_accepted === false) {
                register.message_error = 'terms_is_required';
                this.setState({ register, submited: false });
                buttonBeforeLoading('btn-loading');
                return false;
            }

            if (register.repeat_password !== register.password) {
                register.message_error = 'password_is_not_equal';
                this.setState({ register, submited: false });
                buttonBeforeLoading('btn-loading');
                return false;
            }

            let response = await registerUser(register);
            if (response.status === true) {
                this.setState({ message_register_failed: '', message_register_success: 'registered_successfully', submited: false });
                if (this.state.redirectTo) {
                    this.redirectTo();
                }
                else {
                    const prevPage = JSON.parse(localStorage.getItem('prevPage')) || null;
                    localStorage.removeItem('prevPage');
                    setTimeout(() => {
                        window.location.href = prevPage || process.env.REACT_APP_URL
                    }, 1000)
                }
            } else {
                this.setState({ message_register_failed: response.message, message_register_success: '', submited: false });
            }
            buttonBeforeLoading('btn-loading');
        }
        this.seeTerms = (e) => {
            e.preventDefault();
            this.props.history.push('/terms/terms');
        }
        this.seeTermsPolicy = (e) => {
            e.preventDefault();
            this.props.history.push('/terms/policy');
        }
        this.openLogin = () => this.setState({ section_login: 'login' });
        this.openRegister = () => this.setState({ section_login: 'register' });
        this.openForgotPassword = (e) => {
            e.preventDefault();
            this.props.history.push('/forgot-password');
        }

        this.userSession = () => {
            this.setState({ user: this.props.user });
        }
        this.checkRedirectTo = () => {
            const search = this.props.location.search || "";
            if (search) {
                const params = new URLSearchParams(search);
                if (params.get('redirect')) this.setState({redirectTo: params.get('redirect')});
            }
        }
    }

    componentDidMount() {
        this.userSession();
        this.checkRedirectTo();
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {


        const { loaded, message_load, login, register, message_login_failed, message_register_failed, message_register_success, section_login } = this.state;
        require('./css/login.css');
        return (
            <div>
                <div className={`login-container container-fluid mt-3 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                    <main>
                        <div className='row justify-content-center login-wrapper'>
                            <section className={`section-login col-12 col-sm-8 col-md-5 ${section_login === 'login' && 'active'}`}>
                                <div className='container-form-area'>
                                    <form className='form-horizontal' onSubmit={this.submitLogin}>
                                        <fieldset>
                                            <legend><div className='title-section'>{getMessage('welcome_to') + ' - ' + process.env.REACT_APP_NAME}</div></legend>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='login-email' name='email' placeholder=' ' value={login.email} onChange={this.handleChangeLogin} type='email' className='form-control input-md pl-0' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='login-email'>{getMessage('type_your_email')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='login-password' name='password' placeholder=' ' value={login.password} onChange={this.handleChangeLogin} type='password' className='form-control input-md pl-0' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='login-password'>{getMessage('type_your_password')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                {login.message_error.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7' role='alert'>{getMessage(login.message_error)}</div>}
                                                {message_login_failed.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7' role='alert'>{getMessage(message_login_failed)}</div>}
                                                <div className='col-md-12 col-lg-9 col-xl-7'>
                                                    <button type='submit' className='btn btn-primary d-block btn-loading btn-loading-login'>{getMessage('do_login')}</button>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7'>
                                                    <div className='m-auto change-section-login'><a href={process.env.REACT_APP_URL + '/forgot-password'} onClick={(e) => this.openForgotPassword(e)}>{getMessage('forgot_my_password')}</a></div>
                                                </div>
                                                <div className='col-md-12 col-lg-9 col-xl-7 d-block d-lg-none'>
                                                    <div className='m-auto change-section-login'>{getMessage('dont_have_account')} <span onClick={this.openRegister}>{getMessage('create_account')}</span></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </section>
                            <div className='d-none d-lg-block col-1 divide-forms-login'></div>
                            <section className={`section-login col-12 col-sm-8 col-md-5 ${section_login === 'register' && 'active'}`}>
                                <div className='container-form-area'>
                                    <form className='form-horizontal' onSubmit={this.submitRegister}>
                                        <fieldset>
                                            <legend><div className='title-section'>{getMessage('new_account')}</div></legend>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-email' className='form-control input-md pl-0' name='email' placeholder=' ' value={register.email} onChange={this.handleChangeRegister} type='email' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='login-email'>{getMessage('type_your_email')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-first-name' className='form-control input-md pl-0' name='first_name' placeholder=' ' value={register.first_name} onChange={this.handleChangeRegister} type='text' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-first-name'>{getMessage('first_name')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-last-name' className='form-control input-md pl-0' name='last_name' placeholder=' ' value={register.last_name} onChange={this.handleChangeRegister} type='text' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-last-name'>{getMessage('last_name')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-password' className='form-control input-md pl-0' name='password' placeholder=' ' value={register.password} onChange={this.handleChangeRegister} type='password' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-password'>{getMessage('type_your_password')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-repeat-password' className='form-control input-md pl-0' name='repeat_password' placeholder=' ' value={register.repeat_password} onChange={this.handleChangeRegister} type='password' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-repeat-password'>{getMessage('repeat_password')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <ReactInputMask mask='(**) *-****-****' id='register-phone' className='form-control input-md pl-0' name='phone' placeholder=' ' value={register.phone} onChange={this.handleChangeRegister} type='text' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-phone'>{getMessage('phone')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 label-float'>
                                                    <input id='register-sharcode' className='form-control input-md pl-0' name='sharcode' placeholder=' ' value={register.sharcode} onChange={this.handleChangeRegister} type='text' />
                                                    <label className='pl-3 pr-3 control-label' htmlFor='register-sharcode'>{getMessage('sharcode')}</label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='col-md-12 col-lg-9 col-xl-7 mb-2'>
                                                    <div className='terms'>
                                                        <input type='checkbox' name='terms_is_accepted' id='register-terms' checked={register.terms_is_accepted} onChange={() => this.handleChangeRegister({ target: { name: 'terms_is_accepted', value: !register.terms_is_accepted } })} />
                                                        <label htmlFor='register-terms'>
                                                            {getMessage('terms_is_necessary')} <a href={process.env.REACT_APP_TERMS_URL}>{getMessage('terms_of_use')} {getMessage('and')} {getMessage('privacy_policy')}</a>
                                                        </label>
                                                    </div>
                                                </div>
                                                {register.message_error.length > 0 && <div className='alert alert-warning col-md-12 col-lg-9 col-xl-7' role='alert'>{getMessage(register.message_error)}</div>}
                                                {message_register_failed.length > 0 && <div className='alert alert-danger col-md-12 col-lg-9 col-xl-7' role='alert'>{getMessage(message_register_failed)}</div>}
                                                {message_register_success.length > 0 && <div className='alert alert-success col-md-12 col-lg-9 col-xl-7' role='alert'>{getMessage(message_register_success)}</div>}
                                                <div className='col-md-12 col-lg-9 col-xl-7'>
                                                    -                                                        <button type='submit' className='btn btn-primary d-block btn-loading btn-loading-register'>{getMessage('do_register')}</button>
-                                                    </div>
                                            </div>
                                            <div className='form-group d-block d-lg-none'>
                                                <div className='col-md-12 col-lg-9 col-xl-7'>
                                                    <div className='m-auto change-section-login'>{getMessage('have_account')} <span onClick={this.openLogin}>{getMessage('login_account')}</span></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    }
}
export default withRouter(Login);