import React from "react";
import Footer from "~/components/footer";
import Loading from "~/components/loading/loading";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getMessage, buttonBeforeLoading } from "~/assets/utils";
import {
  Col,
  Row,
  Container,
  Alert,
  CustomInput,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import firebase from "../../firebase/index";
import "react-credit-cards/es/styles-compiled.css";
import { createOrder } from "~/assets/requests/product";
import { getFirebaseData } from "~/firebase/functions";

class Transfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message_load: "loading",
      logged: false,
      files: null,
      fileURL: undefined,
      loading: false,
      user: {
        createdAt: 0,
        email: "",
        name: "",
        phone: "",
        status: "",
        surname: "",
        user_id: 0,
        sharcode: "",
      },
      delivery_data: {},
      userData: {
        isCnpj: false,
      },
    };
    this.userSession = async () => {
      let userData = await getFirebaseData(
        `users/${localStorage.getItem("user_id")}`
      );
      if (userData.status === true) {
        this.setState({ userData: userData.data });
      }
    };

    this.handleFileChange = (event) => {
      const upload = event.target.files[0];

      this.setState({ files: upload });
    };

    this.handleChange = (e) => {
      const delivery_data = Object.assign({}, this.state.delivery_data);
      delivery_data[e.target.name] = e.target.value;
      this.setState({ delivery_data });
    };

    this.handleFile = () => {
      let bucketName = "anexos";
      let file = this.state.files;
      let storaRef = firebase.storage().ref(`${bucketName}/${file.name}`);
      let uploadTask = storaRef.put(file);
      return uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, () => {
        let downloadURL = uploadTask.snapshot.downloadURL;
      }, (error) => {
        console.log(error.message);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.setState({ loading: false, fileURL: downloadURL });
          this.payCartCnpj();
        });
      });
    };

    this.payCartCnpj = async () => {
      const { delivery_data } = this.state;
      const order = {
        fileURL: this.state.fileURL || "",
        state: delivery_data.state,
        city: delivery_data.city,
        district: delivery_data.district,
        street: delivery_data.street,
        number: delivery_data.number,
        complement: delivery_data.complement || "",
        fare_price:
          parseInt(delivery_data.deliveryMode) === 1
            ? 0
            : delivery_data.farePrice,
        total_price: delivery_data.total_price,
        zip_code: delivery_data.zip_code,
        fare_delivery: delivery_data.fareDelivery,
        deliveryMode: delivery_data.deliveryMode,
      };

      let response = await createOrder(order);

      if (response.status === true) {
        localStorage.removeItem("cart");
        this.props.alert.success("Aguarde até a aprovação de sua compra!");
        this.props.history.push("/shopping-history");
      } else {
        this.props.alert.error(getMessage("payment_error"));
        this.props.history.push("/shopping-cart");
      }
    };

    this.handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const { files } = this.state;

        if (files !== null) {
          this.handleFile();
          this.setState({loading: true});
        } else {
          this.props.alert.success(
            "Anexe seu comprovante para realizar a compra!"
          );
        }
        return true;
      } catch (error) {
        buttonBeforeLoading("btn-payment");
        return false;
      }
    };
  }

  async componentDidMount() {
    await this.userSession();
    if (!this.props.products.delivery_data.installments)
      this.props.products.delivery_data.installments = 1;
    this.setState(
      {
        message_load: "all_is_ready",
        loaded: true,
        delivery_data: this.props.products.delivery_data,
      },
      () => {}
    );
  }

  render() {
    require("./css/transfer.css");
    const { delivery_data, loaded, loading } = this.state;
    
    return (
      <Loading loaded={loaded}>
        <Container style={{ paddingTop: "100px" }}>
          <Row>
            <Col sm="4" className="mt-3">
              <div className="transfer-wrapper">
                <p>
                  <strong>Banco:</strong> Bradesco
                </p>
                <p>
                  <strong>Conta:</strong> Em espera...
                </p>
                <p>
                  <strong>Agência:</strong> Em espera...
                </p>
              </div>
            </Col>
            <Col sm="4" className="mt-3">
              <div className="transfer-wrapper">
                <p>
                  <strong>Banco:</strong> Paypal
                </p>
                <p>
                  <strong>Conta:</strong> Em espera...
                </p>
                <p>
                  <strong>Agência:</strong> Em espera...
                </p>
              </div>
            </Col>
            <Col sm="4" className="mt-3">
              <div className="transfer-wrapper">
                <p>
                  <strong>Banco:</strong> Itaú
                </p>
                <p>
                  <strong>Conta:</strong> Em espera...
                </p>
                <p>
                  <strong>Agência:</strong> Em espera...
                </p>
              </div>
            </Col>
          </Row>
          <Alert color="info" className="alert-transfer mt-4">
            Envie seu anexo para confirmarmos sua compra!
          </Alert>

          <div className="">
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label id="transfer-label" className="custom-file-label">Importe seu anexo</Label>
                <div class="custom-file">
                  <input 
                  type="file" 
                  data-browse="Importar" 
                  for="transfer-label" 
                  class="custom-file-input" 
                  onChange={this.handleFileChange} 
                  />
                  <label class="custom-file-label">Importar anexo</label>
                </div>
              </FormGroup>
              {parseInt(delivery_data.deliveryMode) === 0 && (
              <div className="row justify-content-center">
                <legend className="col-12 mb-3">Informe seu endereço</legend>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="zip_code"
                      name="zip_code"
                      placeholder=" "
                      defaultValue={delivery_data.zip_code}
                      type="text"
                      className="form-control input-md pl-0"
                      readOnly
                      required
                    />
                    <label
                      className="pl-3 pr-3 control-label"
                      htmlFor="zip_code"
                    >
                      {getMessage("zip_code")}
                    </label>
                  </div>
                </div>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="city"
                      name="city"
                      placeholder=" "
                      defaultValue={delivery_data.city}
                      type="text"
                      className="form-control input-md pl-0"
                      readOnly
                      required
                    />
                    <label className="pl-3 pr-3 control-label" htmlFor="city">
                      {getMessage("city")}
                    </label>
                  </div>
                </div>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="district"
                      name="district"
                      placeholder=" "
                      defaultValue={delivery_data.district}
                      type="text"
                      className="form-control input-md pl-0"
                      readOnly
                      required
                    />
                    <label
                      className="pl-3 pr-3 control-label"
                      htmlFor="district"
                    >
                      {getMessage("district")}
                    </label>
                  </div>
                </div>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="state"
                      name="state"
                      placeholder=" "
                      defaultValue={delivery_data.street}
                      type="text"
                      className="form-control input-md pl-0"
                      readOnly
                      required
                    />
                    <label className="pl-3 pr-3 control-label" htmlFor="state">
                      {getMessage("street")}
                    </label>
                  </div>
                </div>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="number"
                      name="number"
                      placeholder=" "
                      defaultValue={delivery_data.number}
                      onChange={this.handleChange}
                      type="text"
                      className="form-control input-md pl-0"
                      required
                    />
                    <label className="pl-3 pr-3 control-label" htmlFor="number">
                      {getMessage("number")}
                    </label>
                  </div>
                </div>
                <div className="form-group col-12 col-lg-6">
                  <div className="col-12 label-float mr-auto ml-auto">
                    <input
                      id="complement"
                      name="complement"
                      placeholder=" "
                      value={delivery_data.complement}
                      onChange={this.handleChange}
                      type="text"
                      className="form-control input-md pl-0"
                    />
                    <label
                      className="pl-3 pr-3 control-label"
                      htmlFor="complement"
                    >
                      {getMessage("complement")}
                    </label>
                  </div>
                </div>
              </div>
              )}
              <div className="button-transfer">
                <button
                  disabled={loading}
                  className="btn btn-primary btn-payment mb-4"
                >
                  {loading === true ? "Finalizando..." : "Finalizar compra"}
                </button>
              </div>
            </Form>
          </div>
        </Container>
        <Footer />
      </Loading>
    );
  }
}

export default withAlert()(
  withRouter(
    connect((store) => ({
      user: store.user,
      products: store.products,
    }))(Transfer)
  )
);
