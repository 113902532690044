import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import * as currencyFormatter from 'currency-formatter';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//assets
import { system_lang, convertCentsToFloat } from '~/assets/utils';
import { getGroups } from '~/assets/requests/firebase';
import Footer from '~/components/footer';

import NotFound from '~/components/not-found/index';
import LoadingHome from './loading';
import BannerHome from "../../assets/images/img_banner.png";
import { window } from 'globalthis/implementation';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            groups: [],
            products: {},
            message_load: 'loading_products',
            ads_list: [],
            settings_carousel: {
                showThumbs: false,
                showIndicators: true,
                showStatus: false,
                emulateTouch: true,
                useKeyboardArrows: true,
                autoPlay: true,
                infiniteLoop: true,
                transitionTime: 1000
            },
            hovers: []
        }
        this.seeProduct = (product_id) => {
            this.props.history.push('/product/' + product_id);
        }
        this.openGroup = (group_id) => {
            this.props.history.push('/group/' + group_id);
        }
        /**
         * Carrega a imagem do produto
         * @param {*} products_list 
         * @param {*} product_id 
         */
        this.productImage = (products_list, product_id) => {
            try {
                if (this.state.hovers.includes(product_id)) {
                    return (products_list[product_id].images[1] || products_list[product_id].images[0]);
                }
                else {
                    return products_list[product_id].images[0];
                }
            }
            catch (error) {
                return false;
            }
        }
        /**
         * Remove o hover do produto
         * @param {*} product_id 
         */
        this.onProductLeve = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    const indexOf = this.state.hovers.findIndex(item => item === product_id);
                    if (indexOf >= 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.splice(indexOf, 1);
                        this.setState({hovers});
                    }
                }
            }
            catch (error) {
               return false; 
            }
        }
        /**
         * Remove o enter de um produto
         * @param {*} product_id 
         */
        this.onProductEnter = (product_id) => {
            try {
                if (this.state.hovers.length > 0) {
                    if (this.state.hovers.findIndex(item => item === product_id) < 0) {
                        const hovers = Object.assign([], this.state.hovers);
                        hovers.push(product_id);
                        this.setState({hovers});
                    }
                }
                else {
                    const hovers = Object.assign([], this.state.hovers);
                    hovers.push(product_id);
                    this.setState({hovers});
                }
            }
            catch (error) {
               return false; 
            }
        }
        this.loadProducts = (products) => {
            const products_list = Object.assign({}, this.state.products);
            let renderizing = [];
            products.slice(0, 10).forEach((product_id, key) => {
                try {
                    if ( products_list[product_id] ) {
                        let product_price = convertCentsToFloat(products_list[product_id].price);
                        renderizing.push(
                            <div className='group-product-home cursor-pointer' key={key} onClick={() => this.seeProduct(product_id)}>
                                <div className='group-product-img'
                                    onMouseOver={() => this.onProductEnter(product_id)}
                                    onMouseOut={() => this.onProductLeve(product_id)}>
                                    <LazyLoadImage
                                        alt={products_list[product_id].name}
                                        height={152}
                                        width={'auto'}
                                        effect="blur"
                                        src={this.productImage(products_list, product_id)} />
                                </div>
                                <div className='group-product-details'>
                                    <div className='group-product-price pl-3 pr-3 pb-1 text-center'>{products_list[product_id].price && currencyFormatter.format(product_price, { locale: system_lang })}</div>
                                    <div className='group-product-name pl-3 pr-3 pb-2 text-center'>{products_list[product_id].name}</div>
                                </div>
                            </div>
                        );
                    }
                }
                catch(error) {
                    return false;
                }
            });

            return renderizing;
        }

        this.loadBanners = () => {
            const token = localStorage.getItem('CARIMBOSAVENIDA@TOKEN');
            const ads_list = Object.assign([], this.state.ads_list);
            let ads_new_list = [];
            ads_list.map((ads, key) => {
                ads_new_list.push(
                    <a className='banner-container' href={ads.urlAd += `?tokenSession=${token}`} style={{cursor: 'pointer'}} key={key}>
                        <LazyLoadImage
                            className='banner-img img-fluid'
                            alt={ads.name}
                            height={'100%'}
                            width={'auto'}
                            effect="blur"
                            src={BannerHome}
                            />
                    </a>
                )
                return true;
            })
            return ads_new_list;
        }

        this.loadGroupData = async () => {
            let groupsData = await getGroups();
            if ( groupsData.status === true ) {
                this.setState({
                    groups: groupsData.groups,
                    products: groupsData.products,
                    ads_list: groupsData.ads,
                    loaded: true,
                    message_load: 'all_is_ready'
                });
            }
        }
    }

    componentDidMount() {
        this.loadGroupData();
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/home.css');
        const { loaded, groups, ads_list, settings_carousel } = this.state;
        return(
            <LoadingHome hasAds={ads_list.length > 0 ? true : false} loaded={loaded && groups.length > 0}>
                <div className={`container-page container-fluid ${loaded === true && 'show'}`}>
                    <main id="panel" className="panel">
                        {ads_list.length > 0 && <div className='carousel-container mb-md-5 mb-2 mt-3' style={{
                            width: '100%',
                            maxWidth: '1280px'
                        }}><Carousel {...settings_carousel}>{this.loadBanners()}</Carousel></div>}
                        {
                            groups.map((group, key) => {
                                return (
                                    <section className='group-products-content mb-4 pb-5 mt-0 mt-md-3' key={key}>
                                        {
                                        group.banner &&
                                        <div className='group-products-bn'>
                                            <a className='d-block' href={process.env.REACT_APP_URL + '/group/' + group._id} onClick={()=>this.openGroup(group._id)}>
                                                <div className='img-fluid w-100 group-banner-img' style={{backgroundImage: 'url(' + group.banner + ')'}}></div>
                                            </a>
                                        </div>
                                        }
                                        <div className='group-products-title'>{group.name}</div>
                                        {group.products && <div className='row' style={{marginLeft: "-5px"}}>{this.loadProducts(group.products)}</div>}
                                    </section>
                                )
                            })
                        }
                        {groups.length === 0 && <NotFound description='products_not_found' button_status={false}/>}
                    </main>
                </div>
                <Footer />
            </LoadingHome>
        );
    }
}
export default withRouter(HomePage);