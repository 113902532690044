export const messages = {
    cancel: 'Cancelar',
    products_not_found: 'Nenhum produto disponível no momento',
    what_do_you_looking_for: 'O que você está procurando?',
    login: 'Entrar',
    favorites: 'Favoritos',
    shopping_cart: 'Carrinho',
    categories_not_found: 'Categorias não encontradas',
    all_categories: 'Todas as categorias',
    search: 'Pesquisar',
    product: 'Produto',
    category: 'Categoria',
    fill_fields_correctly: 'Preencha os dados corretamente!',
    incorrectly_password_or_doesnt_exist: 'Oops! Dados incorretos. Tente Novamente!',
    email_have_not_account: 'Este e-mail não está vinculado a nenhuma conta!',
    exceded_requests: 'Muitas tentativas de login malsucedidas. Tente novamente mais tarde!',
    suspcious_activity: 'Bloqueamos todos os pedidos deste dispositivo devido a atividade incomum. Tente mais tarde.',
    favourites: 'Favoritos',
    invalid_product_id: 'ID do produto é inválido ou não existe',
    loading: 'Carregando',
    loading_products: 'Carregando produtos',
    loading_product: 'Carregando produto',
    all_is_ready: 'Tudo pronto',
    add_to_cart: 'Adicionar ao carrinho',
    add_to_favorites: 'Adicionar aos favoritos',
    edit_stamp: 'Editar carimbos',
    loading_product_images: 'Carregando imagens do produto',
    loading_similar_products: 'Carregando produtos similares',
    maybe_you_like_it_products: 'Você pode gostar também desses produtos',
    out_of_stock: 'Produto indisponível',
    not_found: 'Nada encontrado',
    none_product_category: 'Nenhum produto para esta categoria',
    go_to_home: 'Ir para à página inicial',
    loading_categories: 'Carregando categorias',
    invalid_category: 'Categoria inválida',
    categories: 'Categorias',
    request_returned_empty: 'A requisição retornou vazio',
    desc_price: 'Maior valor',
    asc_price: 'Menor valor',
    best_avaliation: 'Melhores avaliações',
    order_by: 'Order por',
    filter_search: 'Refinar busca',
    not_to_return: 'Nada para retornar',
    empty_categories: 'Sem categorias',
    type_your_email: 'Digite seu email',
    type_your_password: 'Digite sua senha',
    do_login: 'Entrar',
    first_name: 'Primeiro nome',
    last_name: 'Último nome',
    repeat_password: 'Digite novamente a sua senha',
    welcome_to: 'Bem-vindo',
    new_account: 'Nova conta',
    do_register: 'Registrar-me',
    password_is_required: 'Você precisa precisa digitar uma senha',
    repeat_password_is_required: 'Você precisa digitar novamente sua senha',
    password_is_not_equal: 'As senhas digitadas não são iguais',
    first_name_is_required: 'Você precisa digitar seu nome',
    last_name_is_required: 'Você precisa digitar seu sobrenome',
    email_is_required: 'Você precisa digitar um email',
    email_not_found: 'Email inválido',
    request_failed: 'A requisição falhou',
    auth_user_not_found: 'Usuário não encontrado. Email ou senha estão incorretos',
    auth_email_already_in_use: 'Já existe um usuário com esse email',
    auth_invalid_email: 'Email inválido',
    auth_operation_not_allowed: 'Requisição não permitida',
    auth_weak_password: 'Senha muito fraca. Procure utilizar no mínimo 8 caracteres com símbolos, números e letras',
    auth_wrong_password: 'Senha incorreta',
    full_name_is_required: 'Você precisa digitar seu nome completo',
    terms_is_required: 'Você precisa aceitar nossos termos para continuar',
    terms_is_necessary: 'Para criar uma conta você precisa estar de acordo com os nossos',
    terms_of_use: 'Termos de uso',
    privacy_policy: 'Políticas de privacidade',
    fields_needed: 'Campos necessários não preenchidos',
    registered_successfully: 'Registrado com sucesso',
    dont_have_account: 'Ainda não tem uma conta?',
    create_account: 'Criar uma conta',
    have_account: 'Já possui uma conta?',
    login_account: 'Acessar minha conta',
    forgot_my_password: 'Esqueci minha senha',
    terms: 'Termos',
    forgot_password: 'Esqueceu sua senha?',
    recovery_password: 'Recuperar senha',
    recovery: 'Recuperar',
    send: 'Enviar',
    settings: 'Configurações',
    shopping_history: 'Histórico de compras',
    account_email: 'Email da conta',
    emal_recovery_sended: 'Um e-mail de recuperação foi enviado à sua conta. Verifique a caixa de entrada.',
    password_changed: 'Senha alterada com sucesso',
    type_new_password: 'Digite uma nova senha',
    repeat_new_password: 'Digita novamente a nova senha',
    save_new_password: 'Salvar nova senha',
    change_password: 'Alterar senha',
    my_account: 'Minha conta',
    logout: 'Sair',
    save_changes: 'Salvar alterações',
    email: 'E-mail',
    general_settings: 'Configurações Gerais',
    save_success_changes: 'Alterações salvas com sucesso',
    invalid_user: 'Usuário inválido',
    none_favorites: 'Não há favoritos adicionados',
    remove_from_favorites: 'Remover dos favoritos',
    none_products_on_favorites: 'Parece que você ainda não adicionou nenhum produto aos seus favoritos',
    quantity: 'Quantidade',
    price: 'Preço',
    total: 'Total',
    checkout: 'Continuar',
    continue_to_shopping: 'Continue comprando',
    remove_from_cart: 'Remover do carrinho',
    none_product_in_cart: 'Você ainda não adicionou nenhum produto ao seu carrinho',
    none_product_added: 'Nenhum produto adicionado',
    details: 'Detalhes',
    nothing_location_available: 'Nenhuma localização disponível pra entregas no momento',
    type_cep_address: 'Digite o CEP de entrega',
    unavailable_to_shopping: 'Desculpe, mas ainda não estamos entregando nesse endereço',
    payment: 'Pagamento',
    transfer: 'Transferir',
    do_payment: 'Efetuar pagamento',
    card_number: 'Número do cartão',
    card_user_name: 'Nome do titular do cartão',
    card_expiry: 'Validade do cartão',
    card_cvc: 'Código de segurança do cartão',
    freight_fare: 'Tarifa de frete',
    invoice_address: 'Endereço de fatura',
    city: 'Cidade',
    district: 'Bairro',
    state: 'Estado',
    street: 'Rua',
    zip_code: 'Código postal',
    number: 'Número',
    complement: 'Complemento',
    national_identification: 'Identificação nacional - CPF',
    national_identification_name: 'Nome do destinatário',
    national_identification_email: 'E-mail para contato',
    invalid_order: 'Pedido inválido',
    payment_successed: 'Pagamento realizado com sucesso, aguarde seu pedido ser entregue.',
    payment_error: 'Não foi possível processar o pagamento no momento',
    required_fields: 'Campos obrigatórios não foram preenchidos',
    card_national_identification: 'CPF do titular do cartão',
    installments: 'Parcelas:',
    history: 'Histórico',
    none_order: 'Nenhum histórico de pedido',
    have_not_order: 'Nenhuma compra foi efetuada ainda',
    phone: 'Telefone',
    phone_is_required: 'Você precisa fornecer o seu número de Telefone',
    order_approved: 'Pagamento aprovado',
    order_pending: 'Pagamento pendente',
    order_finished: 'Pedido entregue',
    order_canceled: 'Pedido cancelado',
    order_time: 'Data do pedido',
    cannot_create_order: 'Não foi possível realizar seu pagamento',
    fare: 'Frete',
    invalid_group: 'Grupo inválido',
    groups: 'Grupos',
    none_product_group: 'Nenhum produto para este grupo',
    results_by: 'Resultados para',
    getting_shop: 'Retirar na Loja',
    yes: 'Sim',
    not: 'Não',
    request_failed: 'Erro na requisição',
    not_quantity_available: 'Estoque não disponível',
    bay_on: 'Compre pelo App',
    payment_types: 'Formas de Pagamento',
    contact_us: 'Fale com a gente',
    policy_exchanges: 'Trocas e cancelamento',
    faq: 'Ajuda',
    policy: 'Políticas',
    cupom_shopping: 'Cupom de desconto',
    none_discount: 'Nenhum disconto encontrado',
    cupom_expired: 'Cupom expirado',
    invalid_expiry_card: 'Válidade do cartão está incorreta. Utilize 2 (dois) digitos para o Mês e 4 (quatro) digitos para o Ano',
    my_orders: 'Meus pedidos',
    sharcode: 'Código de convite',
    sharcode_link: 'Meu código promocional',
    home: 'Home',
    and: 'e'
}