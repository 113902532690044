import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as loadingData from "~/assets/json/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      done: undefined,
      loading: true
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaded !== this.props.loaded) {
        if ( this.props.loaded === true ) {
          this.setState({ loading: false });
          setTimeout(() => {
              this.setState({ done: true });
          }, 1000);
        } else {
          this.setState({ done: false, loading: true });
        }
    }
  }

  render() {
    if ( !this.state.done ) {
        return (
        <div className='container-loading' style={{zIndex: 999999999, background: "#fff"}}>
            <FadeIn>
                <div>
                    <div style={{margin: 'auto',padding: '6%',maxWidth: 480}}>
                      <div className='img-loading-logo'>
                        <img className='img-fluid' src={process.env.REACT_APP_URL + '/svg/logo_preto.svg'} alt={process.env.REACT_APP_NAME}/>
                      </div>
                      <Lottie options={defaultOptions} />
                    </div>
                </div>
            </FadeIn>
        </div>
        );
    } else {
        return this.props.children;
    }
  }
}