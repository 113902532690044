import React from 'react';
import { getMessage, dropdownCustomMenu, getParamUrl } from '~/assets/utils';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getCategories } from '~/assets/requests/firebase';

import './css/header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_input: getParamUrl('q') || '',
            ads_top: {},
            categories: [],
            sideber_status: false,
            fixed_header: false,
            logged: false,
            user: {},
            user_menu_status: false,
            arcordeon: true
        }

        this.submitSearchForm = (e) => {
            e.preventDefault();
            if ( this.state.search_input.length === 0 ) return false;
            window.location.href = process.env.REACT_APP_URL + '/search?q=' + this.state.search_input;
        }
        this.openLogin = () => {
            document.querySelector('body').classList.add('scroll');
            this.setState({sideber_status: false}); 
            this.props.history.push('/login');
        }
        this.openFavourite = () => {
            document.querySelector('body').classList.add('scroll-false');
            this.setState({sideber_status: false}); 
            this.props.history.push('/favorites');
        }
        this.openShoppingCart = () => {
            document.querySelector('body').classList.add('scroll-false');
            this.setState({sideber_status: false}); 
            this.props.history.push('/shopping-cart');
        }
        this.loadCategories = async () => {
            let categoriesData = await getCategories();
            if (categoriesData.status === true) {
                this.setState({categories: categoriesData.categories});
            }
        }
        this.openCategory = (_id, e) => {
            document.querySelector('body').classList.add('scroll-false');
            this.setState({sideber_status: false}); 
            this.props.history.push('/category/' + _id);
        }
        this.renderMenuCategories = () => {
            let menu_categories = [];
            const categoriesData = Object.assign([], this.state.categories);
            categoriesData.map((category, key) => {
                menu_categories.push(
                    <li className='cursor-pointer category-menu-item' key={key}>
                        <a className='p-0' href={process.env.REACT_APP_URL + "/category/" + category._id} onClick={(e)=>this.openCategory(category._id, e)}>{category.name}</a>
                    </li>
                )
                return true;
            });
            return (
             <li className='dropdown-custom link dropdown-hover'>
                <span className='dropbtn noselect d-inline-flex p-0 align-items-center' style={{color: "#282626"}}>
                    <i className='icon-menu medium-icon'></i>
                    {getMessage('all_categories')}
                    <i className='icon-arrow-up invert ml-1'></i>
                </span>
                <ul id='dropdown-submenu-custom' className='dropdown-content'>{menu_categories}</ul>
              </li>
            );
        }
        this.renderSidebarMenuMobile = () => {
            let menu_categories = [];
            const categoriesData = Object.assign([], this.state.categories);
            categoriesData.map((category, key) => {
                menu_categories.push(
                    <li className='cursor-pointer default-li' key={key}>
                        <a href={process.env.REACT_APP_URL + '/category/' + category._id} onClick={(e)=>this.openCategory(category._id, e)}>{category.name}</a>
                    </li>
                )
                return true;
            });

            return menu_categories;
        }
        this.openSidebarMenuHeader = () => {
            if ( this.state.sideber_status === true ) {
                document.querySelector('body').classList.remove('scroll-false');
            } else {
                document.querySelector('body').classList.add('scroll-false');
            }
            this.setState({sideber_status: !this.state.sideber_status}); 
        }
        this.userSession = () => {
            this.setState({user: this.props.user});
        }
        this.logout = (e) => {
            e.preventDefault();
            localStorage.removeItem('user_id');
            window.location.href = process.env.REACT_APP_URL + '/login';
        }
        this.myAccount = (e) => {
            e.preventDefault();
            document.querySelector('body').classList.add('scroll');
            this.setState({sideber_status: false}); 
            this.props.history.push(this.state.logged === false ? '/login' : '/account');
        }
        this.myHistory = (e) => {
            e.preventDefault();
            document.querySelector('body').classList.add('scroll-false');
            this.setState({sideber_status: false}); 
            this.props.history.push('/shopping-history');
        }
        this.openUserMenu = () => {
            this.setState({user_menu_status: !this.state.user_menu_status})
        }
        this.handleArcodeon = () => {
            this.setState({arcordeon: !this.state.arcordeon});
        }
    }

    /**
     * Verifica os parâmetros da url
     * @returns void
     */
    verifyUrlParams = () => {
        const search = this.props.location.search || "";
        if (search && this.props.logged === false) {
            const params = new URLSearchParams(search);
            if (params.get('pedido') && params.get('token'))
                localStorage.setItem('prevPage', JSON.stringify(window.location.href));
        }
    }

    componentDidMount() {
        this.userSession();
        this.verifyUrlParams();
        this.loadCategories();
        dropdownCustomMenu();
        document.addEventListener('scroll', (e) => {
            try {
                let scrollValue = e.target.documentElement.scrollTop;
                let middleHeader = document.querySelector('.header-middle') || undefined;
                
                if (middleHeader === undefined) return false;


                if (scrollValue / 2 > middleHeader.offsetHeight){
                    this.setState({fixed_header: true});
                } else {
                    this.setState({fixed_header: false});
                }
            }
            catch(error) {
                return false;
            }
        })
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            });
        }
    }

    render() {
        try {
            const { search_input, ads_top, user_menu_status, arcordeon } = this.state
            return(
                <div>
                    <header className={`header-container position-fixed ${this.state.fixed_header === true && 'hidden-submenu'}`}>
                        {Object.keys(ads_top).length > 0 &&
                        <div className='header-top'>
                            <a className='d-block' href={ads_top.url}>
                                <img src={ads_top.banner} className='img-fluid w-100' alt={ads_top.name}/>
                            </a>
                        </div>}
                        <div className='header-middle'>
                            <div className='feather-wrapper d-flex flex-wrap flex-md-nowrap'>
                                <div className='d-inline-block d-xl-none menu-sideber-header'>
                                    <div className='open-menu-header' onClick={this.openSidebarMenuHeader}>
                                        <i className='icon-menu medium-icon'></i>
                                    </div>
                                    <div className={`wrapper-menu-header ${this.state.sideber_status === true && 'show'}`}>
                                        <div className='sidebar-mobile-header'>
                                            <div className='top-header-sidebar'>
                                                <div className='user-avatar' onClick={(e)=>this.myAccount(e)}><i className='icon-user'></i></div>
                                                <div className='options'>
                                                    <a className='d-block mb-2 text-left'
                                                        style={{color: "#282626"}}
                                                        href={process.env.REACT_APP_URL + '/account'}
                                                        onClick={(e)=>this.myAccount(e)}>
                                                            {getMessage('my_account')}
                                                        </a>
                                                </div>
                                            </div>
                                            <div className='pl-3 pt-3 pr-3 mb-3 text-left'>
                                                <a className='d-block mb-2 item-sidebar-mobile' style={{color: '#212529'}}
                                                    href={process.env.REACT_APP_URL}>
                                                        <i className='icon-home'></i>
                                                        <strong>{getMessage('home')}</strong>
                                                    </a>
                                            </div>
                                            <div className='pl-3 pr-3 text-left'>
                                                <a className='d-block mb-2 item-sidebar-mobile' style={{color: '#212529'}}
                                                    href={process.env.REACT_APP_URL + '/favorites'}
                                                    onClick={(e)=>this.openFavourite(e)}>
                                                        <i className='icon-ic_fav'></i>
                                                        <strong>{getMessage('favourites')}</strong>
                                                    </a>
                                            </div>
                                            <div className='pl-3 pr-3 text-left'>
                                                <a className='d-block mb-2 item-sidebar-mobile' style={{color: '#212529'}}
                                                    href={process.env.REACT_APP_URL + '/shopping-history'}
                                                    onClick={(e)=>this.myHistory(e)}>
                                                        <i className='icon-ic_pedidos'></i>
                                                        <strong>{getMessage('my_orders')}</strong>
                                                    </a>
                                            </div>
                                            <div className='pl-3 pt-3 pr-3 mb-2 text-left noselect item-sidebar-mobile' onClick={this.handleArcodeon}>
                                                <i className='icon-ic_categorias'></i>
                                                <strong>{getMessage('all_categories')}</strong>
                                                <i className={`icon-arrow-up icon-change ${arcordeon === true && 'active'}`}></i>
                                            </div>
                                        </div>
                                        <ul className={`default-ul ${arcordeon === true && 'active'}`}>{this.renderSidebarMenuMobile()}</ul>
                                        {this.state.logged === true && <div className='sidebar-mobile-footer p-3'>
                                            <a className='d-block item-sidebar-mobile'
                                                href='#'
                                                onClick={this.logout}>
                                                <i className='icon-ic_logout'></i>
                                                {getMessage('logout')}
                                            </a>
                                        </div>}
                                    </div>
                                    {this.state.sideber_status === true && <div className='background-menu-header is-modal' onClick={this.openSidebarMenuHeader}></div>}
                                </div>
                                <div className='header-logo ml-auto mr-0 ml-lg-0'>
                                    <a className='feather-logo-link' href={process.env.REACT_APP_URL}>
                                        <img src={process.env.REACT_APP_URL + '/svg/logo_preto.png'} className='img-logo-wrapper' alt={process.env.REACT_APP_NAME} />
                                    </a>
                                </div>
                                <div className='header-search'>
                                    <form className='form-search-header' onSubmit={this.submitSearchForm}>
                                        <div className='src-input-wpr'>
                                            <button className='submit-search-header' type='submit'><i className='icon-search'></i></button>
                                            <input type='text' name='s' placeholder={getMessage('what_do_you_looking_for')} value={search_input} className='src-input' onChange={(e) => this.setState({search_input:e.target.value})}/>
                                        </div>
                                    </form>
                                </div>
                                <div className='header-user d-none d-inline-block m-0 ml-lg-auto'>
                                    {this.state.logged === false &&
                                        <div className='d-none d-lg-flex align-items-center' onClick={this.openLogin}>
                                            <span className='header-user-icon'><i className='icon-user'></i></span>
                                            <span className='text-user-header text-small d-none d-xl-block cursor-pointer'>{getMessage('login')}</span>
                                        </div>
                                    }
                                    {this.state.logged === true &&
                                    <div className='d-none d-lg-flex align-items-center'>
                                        <span className='header-user-icon'><i className='icon-user'></i></span>
                                        <span className='text-user-header text-small d-none d-xl-block cursor-pointer'>
                                            <li className='dropdown-custom link dropdown-hover d-none d-lg-block teste-user-wrapper'>
                                                <span className='dropbtn noselect d-inline-flex p-0 align-items-center' style={{color: "#282626"}}>
                                                    {this.state.user && this.state.user.name}
                                                    <i className='icon-arrow-down ml-1'></i>
                                                </span>
                                                <ul id='dropdown-submenu-custom' className='dropdown-content pl-0 dropdown-user-menu'>
                                                    <li className='d-block'>
                                                        <a className='d-block' href={process.env.REACT_APP_URL + '/account'} onClick={(e)=>this.myAccount(e)}>{getMessage('my_account')}</a>
                                                    </li>
                                                    <li className='d-block'>
                                                        <a className='d-block-kk' href={process.env.REACT_APP_URL + '/shopping-history'} onClick={(e)=>this.myHistory(e)}>{getMessage('history')}</a>
                                                    </li>
                                                    <li className='d-block'>
                                                        <a className='d-block' href='#' onClick={this.logout}>{getMessage('logout')}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </span>
                                    </div>}
                                </div>
                                {this.state.logged === true &&
                                <div className={`menu-user-mobile ${user_menu_status === true && 'active'}`}>
                                    <div className='d-block'>
                                        <a className='d-block' href={this.state.logged === false ? (process.env.REACT_APP_URL + '/login') : (process.env.REACT_APP_URL + '/account')} onClick={(e)=>this.myAccount(e)}>{getMessage('my_account')}</a>
                                    </div>
                                    <li className='d-block'>
                                        <a className='d-block' href={process.env.REACT_APP_URL + '/shopping-history'} onClick={(e)=>this.myHistory(e)}>{getMessage('history')}</a>
                                    </li>
                                    <div className='d-block'>
                                        <a className='d-block ' href='#' onClick={this.logout}>{getMessage('logout')}</a>
                                    </div>
                                </div>}
                                {user_menu_status === true && <div className='background-menu-user is-modal' onClick={this.openUserMenu}></div>}
                                <div className='header-favourite d-none d-lg-flex' onClick={this.openFavourite}>
                                    <span className='header-favourite-icon'><i className='icon-heart'></i></span>
                                    <span className='text-favourite-header text-small d-none d-xl-block cursor-pointer'>{getMessage('favorites')}</span>
                                </div>
                                <div className='header-shopping-cart mr-2 mr-lg-auto' onClick={this.openShoppingCart}>
                                    <span className='header-shopping-cart-icon'><i className='icon-shopping-cart'></i></span>
                                    <span className='text-shopping-cart-header text-small d-none d-xl-block cursor-pointer'>{getMessage('shopping_cart')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='header-bottom'>
                            <div className='wrapper-ul'>
                                <ul>
                                    {this.renderMenuCategories()}
                                    {
                                        this.state.categories.slice(0, 4).map((category, key) => {
                                            return(
                                                <li className='category-menu-item' key={key}>
                                                    <a className='d-inline-block link noselect' href={process.env.REACT_APP_URL + "/category/" + category._id} onClick={(e)=>this.openCategory(category._id, e)}>{category.name}</a>
                                                </li>
                                                )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div className='header-fallback'></div>
                </div>
            )
        }
        catch(error) {
            return false;
        }
    }
}
export default withRouter(Header);