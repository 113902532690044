import React from 'react';
import Header from '~/components/header';
import Footer from '~/components/footer';
import Loading from '~/components/loading/loading';
import NotFound from '~/components/not-found/index';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as currencyFormatter from 'currency-formatter';

import { getMessage, system_lang, convertCentsToFloat } from '~/assets/utils';
import {
    getProductsOrders
} from '../../assets/requests/product';

class ShoppingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_load: 'loading_products',
            loaded: false,
            orders: [],
            attributes: [],
            total_price: 0,
            zip_code: '',
            orders_available: false,
            fare_price: 0
        }

        this.getProductsOrders = async () => {
            let response = await getProductsOrders();
            if (response.status === true) {
                this.setState({ orders: response.orders, attributes: response.attributes, total_price: response.total_price });
            }
            this.setState({ message_load: 'all_is_ready', loaded: true });
        }
        this.seeProduct = (product_id, e) => {
            e.preventDefault();
            this.props.history.push('/product/' + product_id);
        }
        this.doPaymentOrder = async (order_id) => {
            try {
                const orders = Object.assign([], this.state.orders);
                let indexOf = orders.findIndex(item => item._id === order_id);
                if (indexOf >= 0) {
                    let dataPayment = {};
                    const order = orders[indexOf];
                    dataPayment.deliveryMode = order.deliveryMode;
                    dataPayment.total_price = order.totalPrice - order.fare;
                    dataPayment.zip_code = order.deliveryAddress.zip_code;
                    dataPayment.fare_price = order.fare;
                    dataPayment.farePrice = order.fare;
                    dataPayment.national_identification = '';
                    dataPayment.national_identification_email = '';
                    dataPayment.national_identification_name = '';
                    dataPayment.complement = order.deliveryAddress.additional;
                    dataPayment.street = order.deliveryAddress.address;
                    dataPayment.city = order.deliveryAddress.city;
                    dataPayment.district = order.deliveryAddress.district;
                    dataPayment.number = order.deliveryAddress.number;
                    dataPayment.state = order.deliveryAddress.state;
                    this.props.dispatch({ type: 'SET_FREIGHT_DATA', data: dataPayment })
                    this.props.history.push('/payment?history=' + order_id, {
                      productsByCarimbo: order.productsByCarimbo  
                    });
                }
            }
            catch (error) {
                return false;
            }
        }
        this.loadProduct = () => {
            let order_list = [];
            const { orders, attributes } = this.state
            orders.map((order, key) => {
                let products_list = [];

                if (order && order.products && order.products.length > 0) {
                    order.products.map((product, key) => {
                        let indexOfAttributeSelected = attributes.findIndex(item => item._id === Object.keys(product.attribute_selected.value)[0]);
                        let attribute_name;
                        if (indexOfAttributeSelected >= 0) {
                            let indexOfChildren = attributes[indexOfAttributeSelected].children.findIndex(item => item._id === Object.values(product.attribute_selected.value)[0]);
                            if (indexOfChildren >= 0) attribute_name = attributes[indexOfAttributeSelected].children[indexOfChildren].name;
                        }
                        products_list.push(
                            <div className='history-product row mb-4' key={key}>
                                <div className='col-12 col-md-6 mb-4 md-md-0'>
                                    <div className='media m-auto d-flex flex-wrap'>
                                        <a className='thumbnail pull-left d-block d-lg-inline-block col-12 col-lg-3 text-center' href={process.env.REACT_APP_URL + '/product/' + product._id} onClick={(e) => this.seeProduct(product._id, e)}>
                                            <img className='media-object img-fluid' alt={process.env.REACT_APP_NAME} src={product.images[0]} />
                                        </a>
                                        <div className='media-body col-12 col-lg-9 text-center text-lg-left'>
                                            <h4 className='media-heading'>
                                                <a href={process.env.REACT_APP_URL + '/product/' + product._id} onClick={(e) => this.seeProduct(product._id, e)}>{product.name}</a>
                                            </h4>
                                            <div className='product-details'>
                                                <span>{getMessage('details')}:</span>
                                                <div className='name-attribute'>{indexOfAttributeSelected >= 0 && attributes[indexOfAttributeSelected].name} {attribute_name}</div>
                                                <div className='name-attribute'>{getMessage('quantity')}: {product.quantity}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='row align-items-center'>
                                        <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2 mb-lg-2 mb-3 mb-xl-0 text-center'>
                                            <span className='d-inline'>{getMessage('price')}: </span>
                                            <strong>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</strong>
                                        </div>
                                        <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-2 order-sm-2 order-md-2 order-lg-2 order-xl-3 mb-lg-2 mb-3 mb-xl-0 text-center'>
                                            <span className='d-inline'>{getMessage('total')}: </span>
                                            <strong>{product.price && currencyFormatter.format((convertCentsToFloat(product.price) * product.quantity), { locale: system_lang })}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    });
                }
                if (order.productsByCarimbo.length > 0) {
                    order.productsByCarimbo.map((product, key) => {
                        products_list.push(
                            <div className='cart-product row mb-5' key={key}>
                                <div className='col-12 col-md-6 mb-4 md-md-0'>
                                    <div className='media m-auto d-flex flex-wrap'>
                                        <a
                                            className='thumbnail pull-left d-block d-lg-inline-block col-12 col-lg-3 text-center'
                                            href='javascript:void(0);'>
                                            <img
                                                className='media-object img-fluid'
                                                alt={process.env.REACT_APP_NAME}
                                                src={process.env.REACT_APP_API + '/uploads/' + product.imagem_url_carimbo} />
                                        </a>
                                        <div className='media-body col-12 col-lg-9 text-center text-lg-left'>
                                            <h4 className='media-heading'>
                                                <a href='javascript:void(0);'>Carimbo - {product.nome_cor_carimbo}</a>
                                            </h4>
                                            <div className='product-details'>
                                                <span>{getMessage('details')}:</span>
                                                <div className='name-attribute'>Tinta na cor {product.nome_cor_tinta}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='row align-items-center'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-auto order-sm-3 order-3 order-md-3 order-lg-3 order-xl-1 text-center mb-3 mb-xl-0'>
                                        </div>
                                        <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-1 order-sm-1 order-md-1 order-lg-1 order-xl-2 mb-lg-2 mb-3 mb-xl-0 text-center'>
                                            <span className='d-inline'>{getMessage('price')}: </span>
                                            <strong>{product.price && currencyFormatter.format(convertCentsToFloat(product.price), { locale: system_lang })}</strong>
                                        </div>
                                        <div className='col-6 col-sm-auto col-md-6 col-lg-6 col-xl-auto order-2 order-sm-2 order-md-2 order-lg-2 order-xl-3 mb-lg-2 mb-3 mb-xl-0 text-center'>
                                            <span className='d-inline'>{getMessage('total')}: </span>
                                            <strong>{product.price && currencyFormatter.format((convertCentsToFloat(product.price)), { locale: system_lang })}</strong>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-auto order-4'></div>
                                    </div>
                                </div>
                            </div>
                        )
                        return true;
                    });
                }
                order_list.push(
                    <div className='container-order' key={key}>
                        {products_list}
                        <div className='details-order'>
                            <div className='details-order-item'>
                                {getMessage('order_time')}: {new Date(order.createdAt).toLocaleString()}
                            </div>
                            <div className='details-order-item mb-2'>
                                {order.status === 0 && getMessage('order_canceled')}
                                {order.status === 1 && getMessage('order_pending')}
                                {order.status === 2 && getMessage('order_approved')}
                                {order.status === 3 && getMessage('order_finished')}
                            </div>
                            <div className='details-order-item strong'>
                                {getMessage('fare')}: {currencyFormatter.format(convertCentsToFloat(order.fare), { locale: 'pt-BR' })}
                            </div>
                            <div className='details-order-item strong'>
                                {getMessage('total')}: {currencyFormatter.format(convertCentsToFloat(order.totalPrice), { locale: 'pt-BR' })}
                            </div>
                            <div className='details-order-item mb-2'>
                                {order.status === 1 && <button className='btn btn-danger mt-3' onClick={() => this.doPaymentOrder(order._id)}>{getMessage('do_payment')}</button>}
                            </div>
                        </div>
                    </div>
                );
                return true;
            });
            return order_list;
        }
        this.goToHome = () => {
            this.props.history.push('/');
        }
        this.userSession = () => {
            this.setState({ user: this.props.user });
        }
        this.seePayment = async () => {
            this.props.history.push('/payment');
        }
    }

    componentDidMount() {
        document.querySelector('body').classList.add('scroll');
        this.userSession();
        this.getProductsOrders();
    }

    componentDidUpdate(prevProps) {
        for (let key in this.props) {
            if (this.state.hasOwnProperty(key) && (this.props[key] !== prevProps[key])) {
                const state = Object.assign({}, this.state);
                state[key] = this.props[key];
                this.setState(state);
            }
        }
        if (prevProps.logged !== this.props.logged) {
            this.setState({
                logged: this.props.logged
            })
        }
    }

    render() {
        require('./css/history.css');
        const { message_load, loaded, orders } = this.state;
        return (
            <Loading message={message_load} loaded={loaded}>
                <div className={`history-container container-fluid mt-5 pt-md-4 pb-4 mb-5 ${loaded === true && 'show'}`}>
                    <main className='h-100'>
                        <div className='wrapper-table-history'>
                            {orders.length > 0 && this.loadProduct()}
                            {orders.length === 0 && <NotFound title='none_order' description='have_not_order' />}
                        </div>
                    </main>
                </div>
            </Loading>
        )
    }
}
export default withRouter(connect(store => ({ products: store.products, user: store.user }))(ShoppingHistory));